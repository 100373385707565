import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BuscaCarroRQ, Carro } from '../../model';
import { TURISMO_CARRO } from '../../app.config';
import { ITENSPORPAGINA } from '../../app.config';
import { BuscaBetoCarreroRQ } from '../../model/turismo/beto-carrero.model';

@Injectable({
    providedIn: 'root'
})
export class CarroService {
    constructor(private http: HttpClient) { }

    buscarCarro(busca: BuscaCarroRQ, pagina: Number): Observable<any> {
        return this.http.post<any>(`${TURISMO_CARRO}/api/Carro/buscaasync?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, busca, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    buscarCarroPorId(id): Observable<any> {
        return this.http.get<any>(`${TURISMO_CARRO}/api/Carro/${id}`, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    alterarStatus(alteracao: any): Observable<any> {
        return this.http.put<any>(`${TURISMO_CARRO}/api/carro/${alteracao.Id}`, alteracao, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    buscarPorParametro(param: string, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');
        if (param.length > 0) {
            return this.http.get<any>(`${TURISMO_CARRO}/api/lojascarro/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
        else {
            return this.http.get<any>(`${TURISMO_CARRO}/api/lojascarro?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
    }

    removerCarro(carro: any): Observable<any> {
        return this.http.delete<any>(`${TURISMO_CARRO}/api/Carro/${carro.Id}`);
    }

    reservarCarro(carro: Carro): Observable<any> {
        return this.http.post<any>(`${TURISMO_CARRO}/api/carro/reservar/${carro.Id}`, carro, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    atualizarStatusCarro(carro: any): Observable<any> {
        return this.http.patch<any>(`${TURISMO_CARRO}/api/carro/atualizar/${carro.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    cancelarCarro(carro: any): Observable<any> {
        return this.http.post<any>(`${TURISMO_CARRO}/api/carro/cancelar/${carro.ReservaId}/${carro.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    trocarLojas(buscaCarroRQ: BuscaCarroRQ): Observable<any> {
        return this.http.post<any>(`${TURISMO_CARRO}/api/carro/trocarlojas?guid=${buscaCarroRQ.Guid}`, buscaCarroRQ);
    }
}
