import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';


import { LojasCarro } from '../../model';
import { API, ITENSPORPAGINA } from '../../app.config';
import { LojaCarroDTO } from '../../models_dtos';

@Injectable()
export class LojasCarroService {
    constructor(
        private http: HttpClient,
        private router: Router) { }
    private headers = new HttpHeaders().set('noloader', 't');


    buscarPorParametro(param: string, pagina: Number): Observable<any> {
        if (param.length > 0) {
            return this.http.get<any>(`${API}/api/LojasCarro/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: this.headers });
        } else {
            return this.http.get<any>(`${API}/api/LojasCarro?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: this.headers });
        }
    }

    buscarPorParametroLojasCidade(param: string, pagina: Number): Observable<any> {

        if (param.length > 0) {
            return this.http.get<any>(`${API}/api/BuscarLojasCidade/Lista/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: this.headers });
        } else {
            return this.http.get<any>(`${API}/api/BuscarLojasCidade?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: this.headers });
        }
    }

    buscarPorId(id: number): Observable<any> {
        return this.http.get<any>(`${API}/api/LojasCarro/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any> {
        return this.http.delete<any>(`${API}/api/LojasCarro/${id}`);
    }

    salvar(lojasCarro: LojasCarro): Observable<any> {
        if (lojasCarro.Id > 0) {
            return this.http.put<any>(`${API}/api/LojasCarro/${lojasCarro.Id}`, lojasCarro);
        } else {
            return this.http.post<any>(`${API}/api/LojasCarro/`, lojasCarro);
        }

    }

    buscarPorCidade(id: number): Observable<any> {
        return this.http.get<any>(`${API}/api/LojasCarro/listaporcidade/${id}`);
    }

    buscarPorWebService(webServiceId: number, sigla: string, guid: string): Observable<LojaCarroDTO[]> {
        return this.http.get<LojaCarroDTO[]>(`${API}/api/lojascarro/listaporwebservice/${webServiceId}/${sigla}?guid=${guid}`, { headers: this.headers });
    }
}
